import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Navbar from './Navbar';
import Footer from './Footer';
import Home from './Home';
import Products from './Products';
import ProductDetail from './ProductDetail';
import About from './About'; // Import the About page
import './App.css';

function App() {
  return (
    <Router>
      <div className="App">
        <header>
          <Navbar />
        </header>
        <main>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/products" element={<Products />} />
            <Route path="/product/:id" element={<ProductDetail />} />
            <Route path="/about" element={<About />} /> {/* Add About route */}
          </Routes>
        </main>
        <Footer />
      </div>
    </Router>
  );
}

export default App;

// ProductDetail.jsx
import React from 'react';
import { useParams } from 'react-router-dom';
import './ProductDetail.css';

const productData = {
  1: {
    title: 'Shampoo',
    ingredients: 'Rosemary, Mulberry Root, Ginseng, Sophora Root, Oleanolic acid, Copper Peptide, Capsicine, D-Panthenol, Polyquaternium',
    benefits: 'Promotes hair growth, follicle strength, moisture retention, manageability, thicker, healthier hair',
    price: '1299',
    image: '/images/Shampoo.png',
    description: 'Our shampoo is made of natural ingredients including aloe vera, chamomile, and essential oils. It cleanses your hair gently while keeping it healthy and shiny.'
  },
  2: {
    title: 'Hair Growth Shampoo',
    ingredients: 'Hydrolyzed Wheat Protein, Keratin, Cocoa Butter, Jojoba Butter, Brassica Alcohol',
    benefits: 'Strengthens, nourishes, hydrates, improves texture, conditions hair',
    price: '1099',
    image: '/images/Hair-growth-shampoo.png',
    description: 'This hair growth shampoo contains biotin, keratin, and vitamins that promote hair growth and strengthen hair follicles.'
  },
  3: {
    title: 'Conditioner',
    ingredients: 'Shea Butter, Argan Oil, Avocado Oil, EDTA, Sodium Hyaluronate, D-Panthenol, Keratin Protein, Coco Betaine',
    benefits: 'Nourishment, hydration, luxurious softness, healthy hair',
    price: '899',
    image: '/images/Conditioner.png',
    description: 'Our conditioner is enriched with argan oil and shea butter to deeply nourish and hydrate your hair, leaving it soft and manageable.'
  },
  4: {
    title: 'Hair Mask',
    ingredients: 'Hydrolyzed Wheat Protein, Keratin, Almond Butter, Shea Butter, Aqua, Cetyl Alcohol, Brassica Alcohol, Polyester-11, Polyester-37, Polymnia Sonchifolia Root Juice, Aspartic Acid, Xanthan Gum, Guar Hydroxypropyltrimonium Chloride, Citric Acid',
    benefits: 'Intense hydration, smoothness, radiance',
    price: '1050',
    image: '/images/Hair-mask.png',
    description: 'The hair mask is formulated with avocado oil and honey to provide intense moisture and repair damaged hair.'
  },
  5: {
    title: 'Hair Growth Serum',
    ingredients: 'Aqua, Hops Root Extract, D-Panthenol, Ginseng, Capsicine, Copper Peptide, Sophora Root Extract',
    benefits: 'Promotes hair growth, revitalizes strands',
    price: '2550',
    image: '/images/hair-growth-serum.png',
    description: 'Our hair growth serum is a powerful blend of peptides and botanical extracts that stimulate hair growth and reduce hair loss.'
  },
  6: {
    title: 'Frizz Free Hair Serum',
    ingredients: 'Aqua, Moroccan Argan Oil, Mango Protein, Almond Oil, Cyclopentasiloxane, Dimethicone, D-Panthenol, Broccoli Seed, Mango Seed Extracts',
    benefits: 'Smooths frizz, adds shine, strengthens hair, protects from sun damage',
    price: '699',
    image: '/images/frizz-free-serum.png',
    description: 'The frizz-free serum tames flyaways and frizz with a lightweight formula that includes coconut oil and vitamin E.'
  }
};

const ProductDetail = () => {
  const { id } = useParams();`t`
  const product = productData[id];

  const handlePlaceOrder = () => {
    const phoneNumber = '919510671255'; 
    const productName = product.title;
    const productPrice = product.price;
    const productImageUrl = new URL(product.image, window.location.origin).toString();
    const message = `Hello, I would like to place an order for:%0A%0AProduct: ${encodeURIComponent(
      productName
    )}%0AImage: ${encodeURIComponent(productImageUrl)}%0APrice: ₹${encodeURIComponent(
      productPrice
    )}%0A%0APlease let me know the next steps.`;

    // Create the WhatsApp URL
    const url = `https://wa.me/919510671255?text=${message}`;
    // const url = `https://wa.me/${phoneNumber}?text=${encodeURIComponent(message)}`;
    
    // Open WhatsApp in a new tab
    window.open(url, '_blank');
  };

  return (
    <div className="product-detail-container">
      <div className="product-detail">
        <div className="product-image-container">
          <img src={product.image} alt={product.title} className="product-image" />
        </div>
        <div className="product-info">
          <h1 className="product-title">{product.title}</h1>
          <p className="product-price">₹{product.price}</p>
          <div className="product-description">
            <p>{product.description}</p>
          </div>
          <div className="product-details">
            <h2>Product Details</h2>
            <div className="detail-row">
              <span className="detail-label">Ingredients:</span>
              <span className="detail-value">{product.ingredients}</span>
            </div>
            <div className="detail-row">
              <span className="detail-label">Benefits:</span>
              <span className="detail-value">{product.benefits}</span>
            </div>
          </div>
          <button onClick={handlePlaceOrder} className="order-button">
            Order Now via WhatsApp
          </button>
        </div>
      </div>
    </div>
  );
};

export default ProductDetail;
import React, { useState } from 'react';
import './Product.css';

const Product = ({ image, video, alt, title }) => {
  const [isHovered, setIsHovered] = useState(false);

  return (
    <div
      className="product-card"
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      {isHovered ? (
        <video src={video} autoPlay muted loop className="product-video" />
      ) : (
        <img src={image} alt={alt} className="product-image" />
      )}
      <h3>{title}</h3>
    </div>
  );
};

export default Product;

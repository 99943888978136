import React from 'react';
import { Link } from 'react-router-dom';
import './Home.css';

const Home = () => {
  return (
    <div className="home">
      <section className="hero">
        <div className="hero-content">
          <h1>Discover the Beauty of Bearth</h1>
          <p>Experience natural haircare like never before.</p>
          <p>In the end, it's all about you</p>
          <Link to="/products" className="hero-button">Shop Now</Link>
        </div>
        <img src="images/All-products-together.png" alt="Hero" className="hero-image" />
      </section>

    <section className="featured-products">
  <h2>Our Featured Products</h2>
  <div className="product-grid">
    <Link to="/products" className="product-card">
      <img src="images/Shampoo-serum.png" alt="Shampoo" />
      <div className="product-info">
        <h3 className="product-title">Shampoo</h3>
        <p className="product-description">Revitalize your hair with our nourishing shampoo.</p>
        <button className="cta-button">Shop Now</button>
      </div>
    </Link>
    <Link to="/products" className="product-card">
      <img src="images/Shampoo-conditioner-2.png" alt="Conditioner" />
      <div className="product-info">
        <h3 className="product-title">Conditioner</h3>
        <p className="product-description">Make your hair smooth and manageable with our conditioner.</p>
        <button className="cta-button">Shop Now</button>
      </div>
    </Link>
    <Link to="/products" className="product-card">
      <img src="images/01.png" alt="Serum" />
      <div className="product-info">
        <h3 className="product-title">Serum</h3>
        <p className="product-description">Add shine and strength to your hair with our serum.</p>
        <button className="cta-button">Shop Now</button>
      </div>
    </Link>
  </div>
</section>



    </div>
  );
};

export default Home;

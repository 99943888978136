import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import './Navbar.css';

const Navbar = () => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const handleDropdownToggle = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  return (
    <nav className="navbar">
      <div className="navbar-container">
        {/* Logo */}
        <div className="navbar-brand">
          <Link to="/">
            <img src="/images/bearth_logo.jpg" alt="Bearth Logo" className="navbar-logo" />
          </Link>
        </div>

        {/* Navigation Links */}
        <ul className="navbar-links">
          <li><Link to="/">Home</Link></li>
          
          {/* Products Dropdown */}
          <li className="dropdown">
            <span onClick={handleDropdownToggle}>
              Products <i className={`arrow ${isDropdownOpen ? 'up' : 'down'}`}></i>
            </span>
            {isDropdownOpen && (
              <ul className="dropdown-menu">
                <li><Link to="/products?category=shampoo">Shampoo</Link></li>
                <li><Link to="/products?category=conditioner">Conditioner</Link></li>
                <li><Link to="/products?category=serum">Serum</Link></li>
              </ul>
            )}
          </li>

          <li><Link to="/about">About</Link></li>
        </ul>
      </div>
    </nav>
  );
};

export default Navbar;

import React from 'react';
import Testimonials from '../src/Testimonials'; // Make sure the path is correct
import './About.css';

const About = () => {
  return (
    <div className="about-page">
      <section className="about-section">
        <h1>About Us</h1>
        <p>Welcome to Bearth! We are dedicated to providing the best products and services to our customers.</p>
        <p>Our mission is to deliver high-quality products that make a difference in your daily life.</p>
        <p>We pride ourselves on exceptional customer service and the positive impact we make in the community.</p>
        <p><strong>Place your order today by emailing us on info@bearth.co.in or by WhatsApp: (+91)95106 71255</strong></p>
      </section>

      {/* Add the Testimonials component here */}
      <section className="testimonials-section">
        <h2>What Our Customers Say</h2>
        <Testimonials />
      </section>
    </div>
  );
};

export default About;

import React, { useState, useEffect,useRef } from 'react';
import './Testimonials.css';


const testimonials = [
    {
        name: "Drishti",
        quote: "This product is amazing! It has changed my life for the better.",
        rating: 5,
    },
    {
        name: "Dhaivat",
        quote: "I can't believe how effective this product is. Highly recommended!",
        rating: 4,
    },
    {
        name: "Shruti",
        quote: "A must-have for anyone looking to improve their daily routine.",
        rating: 4.5,
    },
    {
        name: "Krupali",
        quote: "Top-notch quality and fantastic customer service.",
        rating: 5,
    },
    {
        name: "Heena",
        quote: "I've never been more satisfied with a purchase. Five stars!",
        rating: 5,
    },
    {
        name: "Sakshi",
        quote: "Amazing range of products!!",
        rating: 5,
    },
    {
        name: "Shaily",
        quote: "Do try their hair mask, it is promising!",
        rating: 5,
    }
    
];

const Testimonials = () => {
    const [currentTestimonial, setCurrentTestimonial] = useState(0);
    const carouselRef = useRef(null);

    useEffect(() => {
        const intervalId = setInterval(() => {
            setCurrentTestimonial((prev) => (prev + 1) % testimonials.length);
        }, 4000); // Change testimonial every 4 seconds

        return () => clearInterval(intervalId);
    }, []);

    useEffect(() => {
        if (carouselRef.current) {
            const containerWidth = carouselRef.current.clientWidth;
            carouselRef.current.scrollTo({
                left: containerWidth * currentTestimonial,
                behavior: 'smooth',
            });
        }
    }, [currentTestimonial]);

    return (
        <div className="testimonial-carousel" ref={carouselRef}>
            {testimonials.map((testimonial, index) => (
                <div
                    key={index}
                    className={`testimonial-container ${index === currentTestimonial ? 'active' : ''}`}
                >
                    <p className="quote">"{testimonial.quote}"</p>
                    <div className="stars">
                        {Array.from({ length: Math.floor(testimonial.rating) }, (_, i) => (
                            <span key={i} className="star">★</span>
                        ))}
                    </div>
                    <p className="author">- {testimonial.name}</p>
                </div>
            ))}
        </div>
    );
};




export default Testimonials;

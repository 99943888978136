import React from 'react';
import { Link } from 'react-router-dom';
import Product from './Product';
import './Products.css';

const products = [
  {
    id: 1,
    image: '/images/Shampoo.png',
    video: '/videos/Shampoo-video.mp4',
    alt: 'Shampoo-1',
    title: 'Shampoo',
    description: 'Our shampoo is made of natural ingredients including aloe vera, chamomile, and essential oils. It cleanses your hair gently while keeping it healthy and shiny.'
  },
  {
    id: 2,
    image: '/images/Hair-growth-shampoo.png',
    video: '/videos/Hair-growth-shampoo-2.mp4',
    alt: 'Hair-growth-shampoo',
    title: 'Hair Growth Shampoo',
    description: 'This hair growth shampoo contains biotin, keratin, and vitamins that promote hair growth and strengthen hair follicles.'
  },
  {
    id: 3,
    image: '/images/Conditioner.png',
    video: '/videos/Conditioner-video.mp4',
    alt: 'Conditioner',
    title: 'Conditioner',
    description: 'Our conditioner is enriched with argan oil and shea butter to deeply nourish and hydrate your hair, leaving it soft and manageable.'
  },
  {
    id: 4,
    image: '/images/Hair-mask.png',
    video: '/videos/Hair-mask.mp4',
    alt: 'Hair-mask',
    title: 'Hair Mask',
    description: 'The hair mask is formulated with avocado oil and honey to provide intense moisture and repair damaged hair.'
  },
  {
    id: 5,
    image: '/images/hair-growth-serum.png',
    video: '/videos/Hair-growth-serum-video.mp4',
    alt: 'Hair-growth-serum',
    title: 'Hair Growth Serum',
    description: 'Our hair growth serum is a powerful blend of peptides and botanical extracts that stimulate hair growth and reduce hair loss.'
  },
  {
    id: 6,
    image: '/images/frizz-free-serum.png',
    video: '/videos/Frizz-free-serum-video.mp4',
    alt: 'frizz-free-serum',
    title: 'Frizz-Free Serum',
    description: 'The frizz-free serum tames flyaways and frizz with a lightweight formula that includes coconut oil and vitamin E.'
  }
];

const Products = () => {
  return (
    <section className="products">
      {products.map((product, index) => (
        <Link to={`/product/${product.id}`} key={index}>
          <Product
            image={product.image}
            video={product.video}
            alt={product.alt}
            title={product.title}
            description={product.description}
          />
        </Link>
      ))}
    </section>
  );
};

export default Products;
